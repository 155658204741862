import React, { Component, Fragment } from "react";
import videosInit from "./videosdata.json";
import { Tab, Nav } from "react-bootstrap";
import Data from "./Data";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../preloadPage/preload";
const Content = () => {
    const [videos, setJsonData] = useState(videosInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a753a8d69abc065dad2e85"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    return (
        <Fragment>
            {preload && <Preload />}
            <div className="section">
                <div className="container">
                    <Tab.Container defaultActiveKey="tab1">
                        <Tab.Content>
                            <Tab.Pane eventKey="tab1">
                                <div className="row">
                                    <Data data={videos} />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Content;
