import React, { Fragment } from "react";
import Content from "./Content";

const VideosData = () => {
    return (
        <Fragment>
            <Content />
        </Fragment>
    );
};

export default VideosData;
