import React from "react";
import Card from "react-bootstrap/Card";

const Data = ({ data }) => {
    const Data = data.map((item, i) => {
        return (
            <div
                className='col-lg-4 col-md-6 col-sm-12'
                key={i}
                style={{ marginBottom: "20px", marginTop: "20px" }}
            >
                <a href={item.video} download>
                    <Card style={{ width: "30rem" }}>
                        <iframe
                            title={item.id}
                            src={item.video}
                            style={{ height: "20rem" }}
                        ></iframe>
                        <Card.Title
                            style={{
                                fontSize: "20px",
                                margin: "0px",
                            }}
                        >
                            {item.title}
                        </Card.Title>
                    </Card>
                </a>
            </div>
        );
    });
    return Data;
};

export default Data;
