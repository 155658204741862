import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import VideosData from "../../components/videosdata";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import { normalizedData } from "@utils/functions";

const VideoGallery = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Video Gallery" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Video Gallery"
            />
            <Container>
                <VideosData />
            </Container>
        </Layout>
    );
};
VideoGallery.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query VideoGalleryQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default VideoGallery;
